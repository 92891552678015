import {
  ApiOutlined,
  AppstoreTwoTone,
  AudioTwoTone,
  BranchesOutlined,
  BuildTwoTone,
  BulbTwoTone,
  CheckSquareTwoTone,
  ControlTwoTone,
  CopyTwoTone,
  DatabaseTwoTone,
  ExpandOutlined,
  FileSearchOutlined,
  FileTextTwoTone,
  FilterTwoTone,
  GoldTwoTone,
  InteractionTwoTone,
  LoginOutlined,
  LogoutOutlined,
  OrderedListOutlined,
  PartitionOutlined,
  PieChartTwoTone,
  ProfileTwoTone,
  ReadOutlined,
  SearchOutlined,
  ToolOutlined,
  ToolTwoTone,
} from '@ant-design/icons';

export const familiesIcon = {
  default: ApiOutlined,
  audio: AudioTwoTone,
  augmenters: ExpandOutlined,
  builders: BuildTwoTone,
  caching: FileSearchOutlined,
  classifiers: ProfileTwoTone,
  converters: InteractionTwoTone,
  embedders: CopyTwoTone,
  evaluators: ControlTwoTone,
  extractors: PieChartTwoTone,
  fetchers: FileTextTwoTone,
  generators: BulbTwoTone,
  inputs: LoginOutlined,
  joiners: PartitionOutlined,
  others: AppstoreTwoTone,
  outputs: LogoutOutlined,
  preprocessors: ToolTwoTone,
  rankers: OrderedListOutlined,
  readers: ReadOutlined,
  retrievers: FilterTwoTone,
  routers: BranchesOutlined,
  samplers: GoldTwoTone,
  tools: ToolOutlined,
  validators: CheckSquareTwoTone,
  websearch: SearchOutlined,
  writers: DatabaseTwoTone,
};

export const enum StudioYamlTabsKeys {
  INDEXING_YAML = 'indexing_yaml',
  QUERY_YAML = 'query_yaml',
}

export const enum StudioDcPipelines {
  INDEXING = 'indexing',
  QUERY = 'query',
}

export enum CodeFormat {
  YAML = 'yaml',
  PYTHON = 'python',
}

export const STUDIO_NODE_COMPONENT = 'studioNode';
export const STUDIO_EDGE_COMPONENT = 'studioEdge';

export const STUDIO_YAML_TABS_LABELS = [
  {
    key: StudioYamlTabsKeys.INDEXING_YAML,
    label: 'Indexing',
    tooltip:
      'Clean up your files and break them into searchable chunks. Indexing pipeline prepares your data  for search.',
  },
  {
    key: StudioYamlTabsKeys.QUERY_YAML,
    label: 'Query',
    tooltip:
      'Define the steps to find and deliver relevant answers to your queries. Query pipeline is a recipe for executing queries.',
  },
];

export const STUDIO_UNSAVED_CHANGES_LOGOUT_WARNING = {
  title: 'Unsaved Changes',
  content:
    'Your are about to delete all the components from the canvas. This action is irreversible.',
  okText: 'Back to editing',
  cancelText: 'Logout',
};

export const STUDIO_CLEAR_ALL_WARNING = {
  title: 'Clear All?',
  content: `Are you sure you want to clear all nodes and connections?`,
};

export const DOWNLOAD_MODAL_OPENED_WARNING = {
  title: 'YAML opened in another tab',
  description:
    'The YAML code is currently display in the download modal, please switch or close tab to be able to view YAML.',
};

export const PIPELINE_SAVED_SUCCESS = 'Pipeline saved.';

export const STUDIO_INVALID_YAML = `We can't display the pipeline right now because of issues in the YAML code`;

export const STUDIO_INVALID_YAML_RESULT = {
  title: 'Visualisation is not available',
  subtitle: `We can't display the <%tabName%> pipeline right now because of issues in the YAML code. Switch to YAML view to review and update the code.`,
};

export const STUDIO_SUCCESS_MESSAGES = {
  INDEXING_YAML_COPIED: 'Indexing YAML copied to clipboard',
  QUERY_YAML_COPIED: 'Query YAML copied to clipboard',
};

export const STUDIO_ERRORS_MESSAGES = {
  INVALID_CONNECTION: 'Error while validating connection',
  INVALID_TYPE_CONNECTION:
    'Connection %sourceHandle% to %targetHandle% is not possible. The types are not compatible.',
};

export const STUDIO_ACTION_BUTTONS_LABELS = {
  CLEAR_ALL: 'Clear all',
  SAVE: 'Save',
  EXPORT_CODE: 'Export code',
  COPY_YAML: 'Copy YAML',
  YAML_EDITING: 'YAML Editing',
};

export const ALWAYS_VALID_SOURCES = ['Filters'];

export const EMPTY_YAML =
  'components: {}\nconnections: []\nmax_runs_per_component: 100\nmetadata: {}\n';

export const READ_ONLY_INDEXING_YAML_BANNER = `The indexing pipeline is locked during deployment to prevent accidental changes. To update it, %undeploy% the pipeline, then redeploy when ready. This ensures all files are reindexed correctly with your updated settings.`;
export const READ_ONLY_INDEXING_LABEL_BANNER = 'indexing';
export const READ_ONLY_MESSAGE_EDITOR = `The indexing pipeline is in view-only mode. To edit, undeploy it first.`;

export const PARAMETER_MODAL_INPUT_PLACEHOLDER = '# Type or paste here...';

export const FIND_COMPONENTS_PLACEHOLDER = 'Find components';

// Node constans

export const NODE_HANDLE_POPOVER = {
  CLICK: '%click% for suggestions',
  CLICK_LABEL: 'Click',
  DRAG: '%drag% to connect',
  DRAG_LABEL: 'Drag',
};

export const enum NodeSuggestionsTabsKeys {
  POPULAR = 'popular',
  COMPATIBLE = 'compatible',
}

export const NODE_DUPLICATED_NAME_MESSAGE = 'Name already exists';
export const NODE_CONFIGURE_BUTTON_LABEL = 'Configure';
export const NODE_PARAMETERS_TITLE = 'PARAMETERS';
export const NODE_OPTIONAL_PARAMETERS_TITLE = 'OPTIONAL PARAMETERS';
export const NODE_VERSION_LABEL = 'Version: ';
export const NODE_INPUTS_LABEL = 'Inputs';
export const NODE_OUTPUTS_LABEL = 'Outputs';
export const NODE_COMPONENTS_SUGGESTIONS_LABEL = 'Suggestions';
export const NODE_NO_SUGGESTIONS_LABEL = 'No suggestions';
export const NODE_EMPTY_SUGGESTIONS_LIST = `You're searching for a component that doesn't exist or can't be connected to %sourceNodeName%. 
Connect a component with a matching input or output type.`;
export const NODE_LIMITED_SUGGESTIONS_DISCLAIMER = `This component has limited compatibility.`;
export const NODE_SUGGESTION_UNAVAILABLE = 'Suggestion unavailable';
export const NODE_DESCRIPTION_READ_MORE = 'Read more';
export const CLOSE_BUTTON_LABEL = 'Close';
export const ADD_TO_CANVAS_BUTTON_LABEL = 'Add to canvas';
