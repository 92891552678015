import { get, isNil, isObject, isString } from 'lodash';
import { Dispatch } from 'redux';
import { UNEXPECTED_ERROR_LABEL } from '@constants/common';
import { HTTPStatusCode } from '@constants/enum/common';
import { SilentError } from '@constants/error';
import { addNotification, addSequentialNotification } from '@redux/actions/notificationActions';
import {
  INotification,
  NotificationDuration,
  NotificationMode,
  NotificationType,
} from '@redux/types/types';

interface ErrorNotificationOptions {
  sequential?: boolean;
  duration?: NotificationDuration;
  mode?: NotificationMode;
  title?: string;
  type?: NotificationType;
}

const DEFAULT_OPTIONS: ErrorNotificationOptions = {
  sequential: false,
  type: NotificationType.Error,
  duration: NotificationDuration.default,
  mode: NotificationMode.Message,
};

export const normalizeErrorMessage = (error: unknown): string => {
  if (error instanceof Error) return error.message;

  if (isObject(error) && !isNil(error)) {
    const apiError = get(error, 'response.data.errors[0]');
    if (apiError) return apiError;

    const errorMessage = get(error, 'message');
    if (errorMessage) return errorMessage;
  }

  if (isString(error)) return error;

  return UNEXPECTED_ERROR_LABEL;
};

export const getStatusCode = (error: any) => {
  return get(error, 'response.status', HTTPStatusCode.INTERNAL_SERVER_ERROR);
};

export const handleErrorNotification = (
  error: unknown,
  dispatch: Dispatch,
  options: ErrorNotificationOptions = DEFAULT_OPTIONS,
): void => {
  // Don't show notification for silent errors
  if (error instanceof SilentError) return;

  const notification: INotification = {
    content: normalizeErrorMessage(error),
    type: options.type,
    duration: options.duration,
    mode: options.mode,
    title: options.title,
  };

  if (options.sequential) {
    dispatch(addSequentialNotification(notification));
  } else {
    dispatch(addNotification(notification));
  }
};
