import { datadogRum } from '@datadog/browser-rum';
import { createAsyncThunk } from '@reduxjs/toolkit';
import _get from 'lodash/get';
import userflow from 'userflow.js';
import { auth } from '@utils/auth';
import { handleErrorNotification } from '@utils/error';
import { Navigator } from '@utils/navigator';
import { transformUserProfile } from '@utils/transformation/user';
import {
  assignUserRoleApi,
  getUserApi,
  getUserDetailsApi,
  removeUserFromWorkspaceApi,
} from '@api/users';
import { UserRoles } from '@constants/enum/common';
import { WORKSPACE_ROLES_SAVED_MESSAGE } from '@constants/settings-page';
import { persistor } from '@redux/store';
import {
  FETCH_USER_INFO,
  GET_USER,
  IAuthUserData,
  IUserData,
  IUserProfileData,
  NotificationType,
  USER_ASSIGN_WORKSPACE_ROLE,
  USER_LOGIN,
  USER_LOGOUT,
  USER_REMOVE_USER_FROM_WORKSPACE,
} from '@redux/types/types';
import { addNotification } from './notificationActions';
import { setOrganizationInfo } from './organizationActions';

export const fetchUserInfo = createAsyncThunk(
  FETCH_USER_INFO,
  async (
    { authUser, redirect }: { authUser: IAuthUserData; redirect?: boolean; location?: string },
    { dispatch },
  ) => {
    const response = await getUserDetailsApi();
    const userProfile: IUserProfileData = response.data;
    const user: IUserData = transformUserProfile(authUser, userProfile);
    const { workspaces: accessibleWorkspaces } = user;
    const hasAccessibleWorkspaces = !!accessibleWorkspaces.length;

    // TODO: Refactor organizations
    dispatch(
      setOrganizationInfo({
        organizationName: _get(userProfile, 'organization.name', ''),
        organizationId: _get(userProfile, 'organization.organization_id', ''),
        maxWorkspaces: _get(userProfile, 'organization.max_workspaces', 0),
      }),
    );

    if (redirect && hasAccessibleWorkspaces) {
      if (user.role === UserRoles.SEARCH_USER) Navigator.navigate('/playground');
      else Navigator.navigate('/');
    }

    if (!hasAccessibleWorkspaces) Navigator.navigate('/not-workspaces-access');

    datadogRum.setUser({
      id: user.userID,
      role: user.role,
      organization_name: user.organization,
      organization_type: user.organizationType,
      is_deepset_user: user.email.includes('deepset'),
    });

    return user;
  },
);

export const getUser = createAsyncThunk(GET_USER, async (userId: string, { rejectWithValue }) => {
  try {
    const { data } = await getUserApi(userId);
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const login = () => {
  persistor.purge().then(() => {
    return persistor.flush();
  });
  auth.loginWithRedirect({});

  return {
    type: USER_LOGIN,
  };
};

export const logout = () => {
  persistor.purge().then(() => {
    return persistor.flush();
  });
  userflow.reset();
  auth.logout({ logoutParams: { returnTo: `${window.location.origin}/login` } });
  return {
    type: USER_LOGOUT,
  };
};

export const assignUserWorkspaceRole = createAsyncThunk(
  USER_ASSIGN_WORKSPACE_ROLE,
  async (
    { userId, workspaceName, role }: { userId: string; workspaceName: string; role: UserRoles },
    { dispatch, rejectWithValue },
  ) => {
    try {
      await assignUserRoleApi(userId, { name: workspaceName, role: role.toString() });

      dispatch(
        addNotification({
          content: WORKSPACE_ROLES_SAVED_MESSAGE,
          type: NotificationType.Success,
        }),
      );

      return userId;
    } catch (error) {
      handleErrorNotification(error, dispatch);
      rejectWithValue(error);
    }
  },
);

export const removeUserFromWorkspace = createAsyncThunk(
  USER_REMOVE_USER_FROM_WORKSPACE,
  async (
    { userId, workspaceName }: { userId: string; workspaceName: string },
    { dispatch, rejectWithValue },
  ) => {
    try {
      await removeUserFromWorkspaceApi(userId, { name: workspaceName });

      dispatch(
        addNotification({
          content: WORKSPACE_ROLES_SAVED_MESSAGE,
          type: NotificationType.Success,
        }),
      );

      return userId;
    } catch (error) {
      handleErrorNotification(error, dispatch);
      rejectWithValue(error);
    }
  },
);
